import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer, StickyBottomContainer } from '@/ui/components/Page';
import { useTranslate } from '@/translations';
import { BackButton } from '@/ui/components/BackButton';
import { TranslationKey } from '@/translations/types';
import { Loader } from '@/ui/components/Loader';
import {
  useInspectionsVehicle,
  useInspectionsChecklistDynamicOperations,
  useTNRDetails,
} from '@/api/Inspections';
import { useUpdateInspectionsVehicle } from '@/api/Inspections/hooks/useInspectionsVehicles';
import { InspectionStatusComponent } from '@/modules/Inspection/components/InspectionStatusComponent';

import { LicensePlate } from '@/ui/components/LicensePlate';
import { Button, Card, Col, notification, Row, Tabs } from 'antd';
import { faChevronRight, Icon } from '@/ui/components/Icon';
import { InspectionStatusMap } from '@/utils/constants';
import { CustomCard } from '@/ui/components/CustomCard';
import { HUB_METHODS_NAMES, useWebSockets } from '@/hooks';
import { routes } from '@/routes';
import { AppSearchResponse, Inspection } from '@/types';
import { tabs, fields, getPossibleStatues } from './helpers';
import { InspectionCard } from './components/InspectionCard';
import { SalesDecisionCard } from './components/SalesDecisionCard';
import { STATUSES } from './constants';
import { InspectionPointsTable } from './components/InspectionPointsTable';

export const InspectionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const [api, contextHolder] = notification.useNotification();

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [showSticky, setShowSticky] = useState(false);
  const stickyElementRef = React.useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string>();

  const { isLoading, data, refetch } = useInspectionsVehicle(Number(id));

  const ista = useInspectionsChecklistDynamicOperations(Number(id));
  const { mutateAsync: updateMutateAsync, isLoading: updataStatusLoading } =
    useUpdateInspectionsVehicle(Number(id));
  const TNRdetails = useTNRDetails(Number(id));

  const wsEventHandler = (wsData: AppSearchResponse<Inspection>) => {
    if (wsData.entities[0].id === Number(id)) {
      console.log('refetch inspection trigged by ws');
      refetch();
    }
  };

  useWebSockets(HUB_METHODS_NAMES.SendVehicleInspectionsToUser, wsEventHandler);

  const togglePopup = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
  }, [isPopupVisible]);

  const changeStatus = async (statusId: number) => {
    const res = await updateMutateAsync({
      id: Number(id),
      inspectionStatus: {
        isChanged: true,
        value: statusId,
      },
    });
    const changeResult = res.data?.entities?.[0] || {};
    const success = changeResult?.result === 'OK';
    if (success) {
      togglePopup();

      if (
        [
          InspectionStatusMap.Authorised,
          InspectionStatusMap['Requires Re-submission Review'],
        ].includes(statusId)
      ) {
        api.success({
          message: 'Update inspection status success',
          description: 'Will back to inspections page soon.',
          duration: 3,
          onClose: () => navigate(routes.inspections),
        });
      }
    }
    return success;
  };

  const mechanicalInspection = ista?.data?.entities?.[0];
  const inspection = data?.entities?.[0];
  const tnr = TNRdetails?.data?.entities?.[0];

  const checkScrolledIntoView = useCallback((e: Event) => {
    const elem = stickyElementRef.current;
    const parent = e.currentTarget as HTMLElement;
    if (elem && parent) {
      const docViewTop = parent.scrollTop;
      const elemTop = elem.offsetTop;
      const elemBottom = elemTop + elem.clientHeight / 2;
      const show = docViewTop > elemBottom;
      setShowSticky(show);
    }
  }, []);

  useEffect(() => {
    const rootElement = document.getElementById('scrollContainer');
    if (rootElement) {
      rootElement.addEventListener('scroll', (e) => checkScrolledIntoView(e));
    }

    return () =>
      rootElement?.removeEventListener('scroll', (e) =>
        checkScrolledIntoView(e),
      );
  }, [checkScrolledIntoView]);

  if (!inspection) return <Loader />;

  return (
    <>
      {contextHolder}
      {isLoading || updataStatusLoading ? <Loader /> : null}
      <StickyBottomContainer
        sticky={96}
        show={showSticky}
        className="d-flex align-items-center gap-3"
      >
        <LicensePlate size="large">
          {inspection.regNumber || 'NO REG'}
        </LicensePlate>
        <Row gutter={16} className="h-100 flex-grow-1">
          <Col lg={8} sm={24}>
            <Card size="small" className="h-100">
              <InspectionCard
                data={inspection}
                fields={fields.bannerBasic}
                column={1}
                layout="horizontal"
                size="small"
              />
            </Card>
          </Col>
          <Col lg={8} sm={24}>
            <Card size="small" className="h-100">
              <InspectionCard
                data={inspection}
                fields={fields.bannerInspection}
                column={1}
                layout="horizontal"
                size="small"
              />
            </Card>
          </Col>
          <Col lg={8} sm={24}>
            <Card size="small">
              <InspectionPointsTable data={inspection} />
            </Card>
          </Col>
        </Row>
      </StickyBottomContainer>
      <PageContainer sticky={showSticky ? 96 : undefined}>
        <BackButton title={t('inspection')} linkTo={routes.inspections} />
        <Row gutter={16} ref={stickyElementRef}>
          <Col lg={8} sm={24}>
            <Card className="h-100">
              <InspectionCard data={inspection} fields={fields.basic} />
            </Card>
          </Col>
          <Col lg={8} sm={24}>
            <Card className="h-100">
              <InspectionCard data={inspection} fields={fields.inspection} />
            </Card>
          </Col>
          <Col lg={8} sm={24}>
            <Card className="h-100">
              <CustomCard direction="row" className="mb-2">
                <InspectionCard
                  data={inspection}
                  fields={fields.inspectionStatus}
                  column={1}
                />
                <Button
                  type={isPopupVisible ? 'default' : 'primary'}
                  onClick={togglePopup}
                  disabled={
                    InspectionStatusMap.Authorised ===
                    inspection?.inspectionStatus
                  }
                >
                  Change Status
                  <Icon icon={faChevronRight} />
                </Button>
              </CustomCard>
              <InspectionPointsTable data={inspection} />
            </Card>
          </Col>
        </Row>

        <SalesDecisionCard
          inspection={inspection}
          mechanicalInspection={mechanicalInspection}
          tnr={tnr}
        />

        <Card>
          <Tabs
            defaultActiveKey="1"
            className="custom-tabs"
            items={tabs.map(({ title, component: Component }) => ({
              key: title,
              label: t(title as TranslationKey),
              children: (
                <Component
                  inspection={inspection}
                  onReload={refetch}
                  active={activeTab === title}
                />
              ),
            }))}
            onChange={(key) => setActiveTab(key)}
          />
        </Card>
      </PageContainer>
      <InspectionStatusComponent
        isPopupVisible={isPopupVisible}
        dataSource={getPossibleStatues(STATUSES, inspection.inspectionStatus)}
        onTogglePopup={togglePopup}
        onChangeStatus={changeStatus}
      />
    </>
  );
};
