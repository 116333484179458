export default {
  yes: 'Yes',
  no: 'No',

  title: 'OneView 4PL',
  loginBannerInfoTitle: 'Total supply chain visibility',
  loginBannerInfoDescription:
    'Providing End to End granular visibility of your fully managed, dynamically efficient logistics chain.',
  loginFormTitle: 'Log In',
  forgotPassword: 'Forgot your password?',
  email: 'Email',
  newPasswordColumn: 'New password:',
  password: 'Password',
  reset: 'Reset',
  submit: 'Submit',
  logIn: 'Log in',
  backToLogin: 'Back to login',
  rememberMe: 'Remember me?',
  footerCopyright: 'Copyright © {currentYear} GBA Technologies Ltd',
  generalError: 'Something went wrong, please try again',
  colour: 'Colour',
  colourColon: 'Colour:',
  manufacturedDateColon: 'Manufactured Date:',

  productPipeline: 'Product Pipeline',
  go: 'Go',
  damageArea: 'Damage Area',
  location: 'Location',
  locationColon: 'Location:',
  chooseLocation: 'Choose Location',
  chooseTenant: 'Choose Tenant',
  chooseInspector: 'Choose Inspector',
  chooseSites: 'Choose Sites',
  chooseModel: 'Choose Model',
  pipeline: 'Pipeline',
  byNumberOfVehicles: '(by number of vehicles)',

  vesselsSummary: 'Vessels Summary',
  vehiclePassport: 'Vehicle Passport',

  compound: 'Compound',
  transport: 'Transport',
  vessel: 'Vessel',
  passport: 'Passport',
  pts: 'Stock Sheet',
  vin: 'Vin',
  vinColon: 'Vin:',
  vinTracking: 'VIN Tracking',
  customs: 'Customs',
  customer: 'Customer',
  shippingPlan: 'Shipping Plan',
  documentTypes: 'Document Types',
  documentManagement: 'Document Management',
  adminDocumentManagement: 'Admin Document Management',
  orderSummaryAdmin: 'Order Summary Admin',
  systemAdmin: 'System Admin',
  systemAdministration: 'System Administration',
  locations: 'Locations',
  transportUnits: 'Transport Units',
  companies: 'Companies',
  userRoles: 'User Roles',
  myAccount: 'My Account',
  userProfile: 'User Profile',
  username: 'Username',
  displayName: 'Display Name',
  role: 'Role',
  inspectorAssigned: 'Inspector assigned',
  manageUsers: 'Manage Users',
  tenantCustomization: 'Tenant Customization',
  logOut: 'Log Out',
  checklist: 'Checklist',

  functionSettings: 'Function Settings',

  compoundStorage: 'Compound Storage',
  storageUtilisation: 'Storage Utilisation',
  freeSlots: 'Free Slots',
  utilisation: 'Utilisation',
  vehicleColon: 'Vehicle:',
  numberOfVehicles: 'Number of Vehicles',
  storageChartSeriesUtilisation: 'Utilisation',
  storageChartValueAxisTitle: 'Number of Vehicles',
  userName: 'User name',

  manageCompanies: 'Manage Companies',
  manageTransportUnits: 'Manage Transport Units',

  manageLocations: 'Manage Locations',
  fromLocation: 'From Location',
  toLocation: 'To Location',
  despatchDate: 'Despatch Date',
  chooseDate: 'Choose Date',
  transportSummary: 'Transport Summary',
  transportCompanyColon: 'Transport Company:',
  arrivalDestination: 'Arrival Destination',
  arrivalPostCode: 'Arrival Post Code',
  fleetCode: 'Fleet Code',
  filesColon: 'Files:',
  transaction: 'Transaction',
  transactionCode: 'Transaction Code',
  transactionDate: 'Transaction Date',
  statusCode: 'Status Code',

  vesselTitle: 'Vessels',
  model: 'Model',
  eta: 'ETA',

  orderSummary: 'Order Summary',
  notApplicable: 'N/A',
  billOfLadingUpload: 'Bill of Lading Upload',
  selectFile: 'Select file',
  upload: 'Upload',
  uploadDocument: 'Upload Document',
  download: 'Download',
  fileIsTooLarge: 'File is too large',
  associatedDocuments: 'Associated Documents',

  documentTypeCode: 'Document Type Code',
  documentType: 'Document Type',
  isCustomerPreviewRestricted: 'Is customer preview restricted?',

  addNewDocumentType: 'Add New Document Type',
  addNew: 'Add New',
  new: 'New',
  edit: 'Edit',
  delete: 'Delete',
  unDelete: 'Un-Delete',
  assign: 'Assign',
  unassign: 'Unassign',

  shippingPlanAdmin: 'Shipping Plan Admin',

  tenantManagement: 'Tenant Management',
  tenant: 'Tenant',

  uploadIcon: 'Upload icon',
  selectStyleColor1: 'Select style color 1',
  selectStyleColor2: 'Select style color 2',
  saveChanges: 'Save changes',
  salesGrade: 'Sales Grade',

  manageGBAUsers: 'Manage GBA Users',
  manageTMSUsers: 'Manage TMS Users',
  createTMSUser: 'Create TMS User',
  editTMSUser: 'Edit TMS User',
  gbaUser: 'GBA User',

  inspection: 'Inspection',
  inspectionSearch: 'Inspection Search',
  question: 'Question',
  attributes: 'Attributes',
  fuel: 'Fuel',

  inspectionImages: 'Inspection Images',
  inspStatus: 'Insp.Status',
  changeInspectionStatus: 'Change inspection status',
  registration: 'Registration',
  registrationDate: 'Registration Date',
  client: 'Client',
  group: 'Group',
  inspectionId: 'Inspection ID',
  inspectedAt: 'Inspected At',
  make: 'Make',
  inspectionDate: 'Inspection Date',
  template: 'Template',
  inspector: 'Inspector',
  damage: 'Damage',
  submitDamage: 'Submit Damage',
  authoriseDamage: 'Authorise Damage',
  damageId: 'Damage ID',
  damageReference: 'Damage Reference',
  component: 'Component',
  componentSearch: 'Component Search',
  partComp: 'Part/Comp',
  sector: 'Sector',
  damageCond: 'Damage/Cond',
  damageType: 'Damage Type',
  damageCause: 'Damage Cause',
  damageCondition: 'Damage Condition',
  addDamage: 'Add Damage',
  editDamage: 'Edit Damage',
  updateDamage: 'Update Damage',
  severity: 'Severity',
  repairMethod: 'Repair Method',
  internalGrade: 'Internal Grade',
  workCentre: 'Work Centre',
  responsibility: 'Responsibility',
  shortcutCode: 'Shortcut Code',
  dealer: 'Dealer',

  fault: 'Fault',
  subFault: 'Sub Fault',
  size: 'Size',
  count: 'Count',
  action: 'Action',
  note: 'Note',
  notes: 'Notes',
  mechanicalFailure: 'Mechanical Failure',
  authorizationNotes: 'Authorization Notes',
  inspectionNotes: 'Inspection Notes',
  mechanicalInspectionNotes: 'Mechanical Inspection Notes',
  noNotes: 'No Notes...',
  addNote: 'Add Note',
  frCode: 'FR code',
  labourCost: 'Labour Cost',
  partsCost: 'Parts Cost',
  lineCost: 'Line Cost',
  totalPrice: 'Total Price',
  authStatus: 'Auth status',
  review: 'Review',
  claimReview: 'Claim Review',
  grading: 'Grading',
  log: 'Log',
  approve: 'Approve',
  approveAll: 'Approve All',
  unApprove: 'Un-Approve',
  reject: 'Reject',
  rejectAll: 'Reject All',
  unReject: 'Un-Reject',
  approveStatusQuestion: 'Are you sure you want to approve?',
  unApproveStatusQuestion: 'Are you sure you want to un-approve?',
  rejectStatusQuestion: 'Are you sure you want to reject?',
  unRejectStatusQuestion: 'Are you sure you want to un-reject?',
  warning: 'Warning',
  missingAuthorization:
    'Authorization resolution is missing for some of the damage lines. Please check the damage lines and complete approval procedure.',

  odometer: 'Odometer',
  miles: 'Miles',
  options: 'Options',
  serviceAndKeys: 'Service & Keys',
  summary: 'Summary',
  damageImages: 'Damage Images',
  status: 'Status',
  inspectionStatus: 'Inspection Status',
  inspectionType: 'Inspection Type',
  tnrStatusColon: 'TNR Status:',
  statusColon: 'Status:',
  statusDescriptionColon: 'Status Description:',
  wheelsAndTyres: 'Wheels & Tyres',
  documents: 'Documents',
  charges: 'Charges',
  images: 'Images',
  addImage: 'Add Image',
  pointInTimeReports: 'Point in Time Reports',
  eventViewer: 'Event Viewer',
  mot: 'MOT',
  viewReport: 'View Report',
  brand: 'Brand',
  type: 'Type',
  condition: 'Condition',
  depth: 'Depth',
  parts: 'Parts',
  frCodesAndPartNumbers: 'FR Codes & Part Numbers',
  frCodes: 'FR Codes',
  partNumbers: 'Part Numbers',
  partType: 'Part Type',
  code: 'Code/Part No',
  cost: 'Cost',
  chargePrice: 'Charge Price',

  noAccessAlert:
    'Sorry, you do not have permission to access this page, please refer to your system administrator.',

  prev: 'prev',
  next: 'next',

  unableToPreviewDocType: 'Unable to preview this document type.',
  pleaseDownloadToView: 'Please download to view.',

  ok: 'OK',
  back: 'Back',
  questionColon: 'Question:',
  answerColon: 'Answer:',
  date: 'Date',
  dateTime: 'Date & Time',
  description: 'Description',

  entity: 'Entity',
  entityReference: 'Entity Reference',

  repairMatrices: 'Repair Matrices',
  archive: 'Archive',
  swap: 'Swap',
  add: 'Add',
  import: 'Import',
  importData: 'Import Data',
  createMissingLookupValues: 'Create Missing Lookup Values',
  repairActionMatrix: 'Repair Action Matrix',
  editItem: 'Edit Item',
  addItem: 'Add Item',
  search: 'Search',
  searchItem: 'Search Item',
  select: 'Select',
  quickView: 'Quick View',
  saveQuickView: 'Save View',
  quickViewName: 'Quick View Name',
  clearAll: 'Clear All',
  addFrCode: 'Add Flat Rate Code',
  createFrCode: 'Create Flat Rate Code',
  importFile: 'Import File Details',
  fileType: 'File type should be Excel, first line should be column headers',
  acceptedFile: 'Accepted file',
  excel: 'Excel',
  save: 'Save',
  create: 'Create',
  cancel: 'Cancel',
  confirm: 'Confirm',
  dispatch: 'Dispatch',
  proceed: 'Proceed',
  section: 'Section',
  claimRef: 'Claim Ref',
  excludeReCharge: 'Exclude ReCharge',
  reviewReason: 'Review Reason',
  comment: 'Comment',
  noRegNumber: 'No Reg Number',
  regNumber: 'Reg Number',
  quantity: 'Quantity',
  site: 'Site',
  noData: 'No Data',

  // logs
  user: 'User',
  applicationName: 'Application Name',
  dataType: 'Data Type',
  dataChanges: 'Changes',
  ipAddress: 'IP Address',
  latitude: 'Latitude',
  longitude: 'Longitude',
  time: 'Time',

  statusChange: 'Status Change',
  fieldUpdate: 'Field Update',

  contactName: 'Contact name',
  address: 'Address',
  postCode: 'Post Code',
  country: 'Country',
  phoneNumber: 'Phone number',

  dynamicOperations: 'Dynamic operations',
  engineBay: 'Engine bay',
  engineRunning: 'Engine running',
  electricHybrid: 'Electric/Hybrid',
  interior: 'Interior',
  cumulative: 'Cumulative',
  final: 'Final',
  gradePoints: 'Grade Points',
  gradeScore: 'Grade Score',
  age: 'Age',
  mileage: 'Mileage',
  repairCost: 'Repair cost',
  month: 'month',
  months: 'months',
  autoOK: 'Auto OK',
  autoNOK: 'Auto NOK',
  maintenanceHistory: 'Maintenance history',
  chiptuningDetected: 'Chiptuning detected',
  manualDecision: 'Manual decision',
  approveForRepair: 'Approve for repair',
  rejectForRepair: 'Reject for repair',
  tnrApprove: 'TNR Approved',
};
