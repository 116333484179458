import styled from 'styled-components';
import { Row, Space } from 'antd';

export const StyledRow = styled(Row)`
  margin: 0 -8px;
`;

export const PassportCard = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 16px;
  height: 100%;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 992px) {
    margin-bottom: 16px;
  }
`;

export const FullWidthSpace = styled(Space)`
  width: 100%;
`;
