import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Logo } from '@/ui/components/Logo';
import {
  faSignOut,
  Icon,
  Icons,
  IconDefinition,
  faCircleUser,
} from '@/ui/components/Icon';
import { TenantSwitcher } from '@/ui/components/TenantSwitcher';
import { routes } from '@/routes';
import { useTheme } from 'styled-components';
import { useTranslate } from '@/translations';
import { loginRequest } from '@/auth/config';
import { Button, Tooltip } from 'antd';
import { NavLinkDesktop, NavLinkContent, NavLogo } from './Navigation.styled';

type NavLinkProps = {
  to: string;
  icon: Icons | IconDefinition;
  color: string;
  label: string;
};

const NavLink = ({ to, icon, color, label }: NavLinkProps) => (
  <Tooltip title={label}>
    <NavLinkContent to={to}>
      <div>
        <NavLinkDesktop
          color={color}
          fontSize={Icons[icon as Icons] ? 'unset' : ''}
        >
          <Icon icon={icon} />
        </NavLinkDesktop>
      </div>
    </NavLinkContent>
  </Tooltip>
);

export const Navigation = () => {
  const { primaryBg } = useTheme();
  const { t } = useTranslate();
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logout();
  };

  const handleLogin = () => {
    instance
      .loginRedirect({
        scopes: loginRequest.scopes,
        prompt: 'login',
      })
      .catch((e: Error) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  return (
    <>
      <NavLogo to={routes.root}>
        <Logo />
      </NavLogo>
      {isAuthenticated ? (
        <div className="d-flex align-content-center align-items-center gap-3">
          <TenantSwitcher />
          <NavLink
            to={routes.manageUser}
            icon={faCircleUser}
            color={primaryBg}
            label={t('myAccount')}
          />

          <Tooltip title={t('logOut')}>
            <Button type="text" onClick={handleLogout} style={{ padding: 0 }}>
              <NavLinkDesktop color={primaryBg}>
                <Icon icon={faSignOut} />
              </NavLinkDesktop>
            </Button>
          </Tooltip>
        </div>
      ) : (
        <Button type="primary" size="large" onClick={handleLogin}>
          {t('logIn')}
        </Button>
      )}
    </>
  );
};
