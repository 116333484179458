import React, { FC, useCallback, useState } from 'react';
import { useTranslate } from '@/translations';
import { AddImagePopup } from '@/modules/Inspection/components/tabs/Damage/components/AddImagePopup';
import {
  useDeleteInspectionDamagesImages,
  useInspectionDamagesImages,
} from '@/api/Inspections/hooks/useInspectionsVehicles';
import { DamageActions } from '@/modules/Inspection/components/tabs/Damage/components/DamageActions';

import { Button, Card, Col, Collapse, Input, Row } from 'antd';
import { faAdd, faTrash, Icon } from '@/ui/components/Icon';
import { ImagesLightBox } from '@/ui/components/ImagesLightBox';
import { DamageActionsDataGridSectionType } from './types';

export const DamageMasterDetail: FC<DamageActionsDataGridSectionType> = ({
  inspectionId,
  vehicleDamageId,
  disabled,
  note,
  authorizationNotes,
  masterDetailsDataGridProps,
}) => {
  const { t } = useTranslate();
  const [isImagesOpened, setIsImagesOpened] = useState(false);

  const { data: damagesImagesData, isLoading: isDamagesImagesLoading } =
    useInspectionDamagesImages(vehicleDamageId);
  const { mutate: deleteDamagesImagesMutate } =
    useDeleteInspectionDamagesImages();

  const [isImagePopupVisible, setImagePopupVisibility] = useState(false);

  const toggleImagePopup = useCallback(() => {
    setImagePopupVisibility(!isImagePopupVisible);
  }, [isImagePopupVisible]);

  const renderFooter = (id: number) => (
    <Button
      className="text-muted mt-3"
      style={{ display: 'block', width: '200px' }}
      disabled={disabled}
      onClick={() => deleteDamagesImagesMutate(id)}
    >
      <Icon icon={faTrash} />
      {t('delete')}
    </Button>
  );

  return (
    <Row gutter={16}>
      <AddImagePopup
        isPopupVisible={isImagePopupVisible}
        onTogglePopup={toggleImagePopup}
        vehicleDamageId={vehicleDamageId}
      />

      <Col lg={12} className="d-flex flex-column gap-3">
        <Card>
          <DamageActions
            inspectionId={inspectionId}
            vehicleDamageId={vehicleDamageId}
            masterDetailsDataGridProps={masterDetailsDataGridProps}
            disabled={disabled}
          />
        </Card>

        <Card>
          <h6>{t('notes')}</h6>
          <Input.TextArea value={note} disabled rows={3} />
        </Card>

        {authorizationNotes && (
          <Card>
            <h6>{t('authorizationNotes')}</h6>
            <Input.TextArea value={authorizationNotes} disabled rows={3} />
          </Card>
        )}
      </Col>
      <Col lg={12}>
        {!isDamagesImagesLoading && (
          <Collapse
            onChange={() => setIsImagesOpened(!isImagesOpened)}
            items={[
              {
                label: <b>{t('images')}</b>,
                children: (
                  <>
                    <div className="mb-2 d-flex justify-content-end">
                      <Button
                        shape="circle"
                        disabled={disabled}
                        onClick={toggleImagePopup}
                      >
                        <Icon icon={faAdd} />
                      </Button>
                    </div>

                    {isImagesOpened && damagesImagesData?.entities?.length ? (
                      <ImagesLightBox
                        images={damagesImagesData.entities.map((item) => ({
                          id: item.id,
                          imageFileUrl: item.imageFileUrl,
                          thumbnailImageUrl: item.resizedImageUrl,
                          footer: () => renderFooter(item.id),
                        }))}
                        style={{ maxWidth: '100%' }}
                      />
                    ) : null}
                  </>
                ),
              },
            ]}
          />
        )}
      </Col>
    </Row>
  );
};
