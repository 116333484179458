import React, { useCallback, useEffect } from 'react';
import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation } from '../Navigation';
import { faAlignLeft, faAlignRight, Icon } from '../Icon';
import { SliderContent } from './Slider.styled';

export const Slider = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pages } = useNavigation();
  const [collapsed, setCollapsed] = React.useState(false);
  const routes = pages.appPages.concat(pages.adminPages);
  const items = routes.map((o) => ({
    key: o.to,
    label: o.label,
    icon: <Icon icon={o.icon} />,
    color: o.color,
  }));

  const selectedKeys = routes
    .filter(
      (o) =>
        location.pathname === o.to ||
        o.subRoutes?.some((r) => location.pathname === r),
    )
    .map((o) => o.to);

  const handleMediaQueryChange = useCallback(
    (mql: MediaQueryListEvent | MediaQueryList) => {
      const { matches } = mql;
      if (collapsed !== matches) {
        setCollapsed(matches);
        localStorage.setItem('collapsed', matches.toString());
      }
    },
    [collapsed],
  );

  useEffect(() => {
    const savedCollapsed = localStorage.getItem('collapsed') === 'true';
    setCollapsed(savedCollapsed);

    const mediaQuery = window.matchMedia('(max-width:767px)');
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [handleMediaQueryChange]);

  const onChangeCollapsed = (status: boolean) => {
    setCollapsed(status);
    localStorage.setItem('collapsed', status.toString());
  };

  return (
    <SliderContent collapsed={collapsed}>
      <div
        className={`d-flex justify-content-${collapsed ? 'center' : 'end'} p-3${
          collapsed ? ' ps-4' : ''
        }`}
      >
        <Icon
          className="hoverable-icon"
          icon={collapsed ? faAlignLeft : faAlignRight}
          onClick={() => onChangeCollapsed(!collapsed)}
        />
      </div>
      <Menu
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
        className="border-0"
        selectedKeys={selectedKeys}
        onSelect={(e) => navigate(e.key)}
      />
    </SliderContent>
  );
};
