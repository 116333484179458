import { useMutation, useQuery, UseQueryOptions } from 'react-query';
import { queryClient } from '@/config/reactQuery';
import { partCodeValidator } from '@/utils/validations';
import { AuditLogEvent, FrCodeData, RegularDamagePart } from '@/types';
import { notify } from '@/ui';
import { CarDamageAreaCode } from '@/utils/constants';
import { Select } from '@/types/General/Select';
import { DamageCause, InternalGrading, Severity } from '@/types/Damage/Code';

import { AuthorizationStatus } from '@/modules/Inspection/components/tabs/Damage/components/DamageDataGrid/types';
import { QUERY_KEYS } from '../../queryKeys';
import { inspections } from '../Inspections';

export const useInspectionsReportsInitial = (inspectionId: number) =>
  useQuery(
    QUERY_KEYS.inspectionsReportsInitial,
    async () =>
      (await inspections.getInspectionsReportsInitial(inspectionId)).data,
    { enabled: !!inspectionId },
  );

export const useInspectionsVehiclesFilters = () =>
  useQuery(
    QUERY_KEYS.inspectionsVehicles,
    async () => (await inspections.getInspectionsVehiclesFilters()).data,
  );

export const useUpdateInspectionsVehicle = (id: number) =>
  useMutation(inspections.updateInspectionsVehicle, {
    onSuccess: async (resData) => {
      const data = resData?.data?.entities?.[0];
      if (!data) return;

      const { message, result } = data;
      if (result === 'Failed') {
        notify(
          {
            message,
            position: {
              my: 'center top',
              at: 'center top',
            },
          },
          'error',
          10000,
        );
      } else {
        await queryClient.invalidateQueries([
          QUERY_KEYS.inspectionsVehicle,
          id,
        ]);
      }
    },
    onError: async ({ message }) => {
      notify(
        {
          message,
          position: {
            my: 'center top',
            at: 'center top',
          },
        },
        'error',
        10000,
      );
    },
  });

export const useInspectionsVehicle = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehicle, id],
    async () => (await inspections.getInspectionsVehicle(id)).data,
  );

export const useInspectionsVehicleConditions = () =>
  useQuery(
    QUERY_KEYS.conditions,
    async () => (await inspections.getInspectionsVehicleConditions()).data,
  );

// GET Damages
export const useInspectionsVehiclesDamagesLines = (id: number) =>
  useQuery([QUERY_KEYS.damages, id], async () => {
    const { data } = await inspections.getInspectionsVehiclesDamagesLines(id);
    data.entities.forEach((item) => {
      const typedColorString =
        item.authorizationStatus as keyof typeof AuthorizationStatus;
      const status = AuthorizationStatus[typedColorString];
      item.authorizationStatusNum = status;
    });
    return data;
  });

export const useUpdateInspectionsVehicleDamages = () =>
  useMutation(inspections.updateInspectionsVehicleDamage);

export const useBatchUpdateInspectionsVehicleDamagesStatus = () =>
  useMutation(inspections.batchUpdateInspectionsVehicleDamagesStatus);

export const useUpdateInspectionsVehicleDamageActions = () =>
  useMutation(inspections.updateInspectionsVehicleDamageActions);

export const useUpdateInspectionsVehicleDamageParts = () =>
  useMutation(inspections.updateInspectionsVehicleDamageParts);

export const useInspectionsVehicleSeverityActions = (id: number) =>
  useQuery(
    [QUERY_KEYS.severityActions],
    async () =>
      (await inspections.getInspectionsVehicleSeverityActions(id)).data,
  );

export const useInspectionsVehicleRepairActions = () =>
  useQuery(
    QUERY_KEYS.repairActions,
    async () => (await inspections.getInspectionsVehicleRepairActions()).data,
  );

export const useInspectionsVehiclesParts = (partNumber: string) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehiclesParts, partNumber],
    async () =>
      (await inspections.getInspectionsVehiclesParts(partNumber)).data,
    {
      enabled: partCodeValidator(partNumber),
    },
  );

export const useInspectionsVehiclesDamageActionsByFlatRateCode = (
  frCode: string,
  options?:
    | Omit<
        UseQueryOptions<FrCodeData, unknown, FrCodeData, string[]>,
        'queryKey' | 'queryFn'
      >
    | undefined,
) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehiclesPartsByFrCode, frCode],
    async () =>
      (
        await inspections.getInspectionsVehiclesDamageActionsByFlatRateCode(
          frCode,
        )
      ).data,
    options,
  );

// GET FR Codes
export const useInspectionsVehicleDamageActions = (id: number) =>
  useQuery(
    [QUERY_KEYS.damageActions, id],
    async () => (await inspections.getInspectionsVehicleDamageActions(id)).data,
    { staleTime: Infinity },
  );

export const useInspectionsVehicleDamageParts = (options?: {
  enabled?: boolean;
}) =>
  useQuery(
    [QUERY_KEYS.damageParts],
    async () => {
      const response = await inspections.getInspectionsVehicleDamageParts();

      const damageParts = response?.data?.damageParts
        .filter(({ areaCode, partCode }) => areaCode && partCode)
        .sort((a, b) => {
          if (
            a.partDescription?.toLowerCase() < b.partDescription?.toLowerCase()
          ) {
            return -1;
          }
          if (
            a.partDescription?.toLowerCase() > b.partDescription?.toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });

      const mapToSelectItemsGroupedByCarArea = (areas: RegularDamagePart[]) =>
        areas.reduce(
          (
            acc: Record<CarDamageAreaCode, Select[]>,
            { partCode, partDescription, areaCode }: RegularDamagePart,
          ) => ({
            ...acc,
            [areaCode]: [
              ...(acc[areaCode] || []),
              {
                value: partCode,
                label: partDescription,
              },
            ],
          }),
          {} as Record<CarDamageAreaCode, Select[]>,
        );

      return {
        selectItems: mapToSelectItemsGroupedByCarArea(damageParts),
        damageParts,
        getPartByCode: (partCode?: string) =>
          damageParts.find((part) => partCode === part.partCode),
      };
    },
    options,
  );

export const useInspectionsVehicleDamagePartsById = (id: number) =>
  useQuery(
    [QUERY_KEYS.damagePartsById, id],
    async () =>
      (await inspections.getInspectionsVehicleDamagePartsById(id)).data,
  );

export const useDeleteInspectionsVehicleDamageAction = () =>
  useMutation(inspections.deleteInspectionsVehiclesDamageAction);

export const useDeleteInspectionsVehicleDamagePart = () =>
  useMutation(inspections.deleteInspectionsVehiclesDamagePart);

export const useCreateInspectionsVehiclesFlatRateCode = () =>
  useMutation(inspections.createInspectionsVehiclesFlatRateCode);

export const useInspectionsVehiclesInspectors = (options?: {
  enabled?: boolean;
}) =>
  useQuery(
    QUERY_KEYS.inspectionsVehiclesInspectors,
    async () => (await inspections.getInspectionsVehiclesInspectors()).data,
    options,
  );

export const useInspectionsVehiclesInspector = (
  id: number,
  options?: {
    enabled?: boolean;
  },
) =>
  useQuery(
    QUERY_KEYS.inspectionsVehiclesInspectors,
    async () => (await inspections.getInspectionsVehiclesInspector(id)).data,
    options,
  );

export const useInspectionsVehiclesNotes = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehiclesNotes, id],
    async () => (await inspections.getInspectionsVehiclesNotes(id)).data,
  );

export const useUpdateInspectionsVehiclesNotes = (id: number) =>
  useMutation(inspections.updateInspectionsVehiclesNotes, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QUERY_KEYS.inspectionsVehiclesNotes,
        id,
      ]);
    },
  });

export const useInspectionsLogs = (id: number) =>
  useQuery(QUERY_KEYS.logs, async () => {
    const { data } = await inspections.getInspectionsLogs(id);
    data.entities.forEach((item) => {
      item.eventType =
        AuditLogEvent[item.eventType as unknown as keyof typeof AuditLogEvent];
    });
    return data;
  });

export const useInspectionsChecklistDynamicOperations = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsChecklistDynamicOperations, id],
    async () =>
      (await inspections.getInspectionsChecklistDynamicOperations(id)).data,
  );

export const useInspectionsChecklistElectricHybrid = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsChecklistElectricHybrid, id],
    async () =>
      (await inspections.getInspectionsChecklistElectricHybrid(id)).data,
  );

export const useInspectionsChecklistEngineRunning = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsChecklistEngineRunning, id],
    async () =>
      (await inspections.getInspectionsChecklistEngineRunning(id)).data,
  );

export const useInspectionsChecklistInterior = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsChecklistInterior, id],
    async () => (await inspections.getInspectionsChecklistInterior(id)).data,
  );

export const useInspectionDamagesImages = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionDamageImage, id],
    async () => (await inspections.getInspectionsDamagesImages(id)).data,
    { staleTime: Infinity },
  );

export const useUpdateInspectionDamagesImages = (id: number) =>
  useMutation(inspections.uploadInspectionsDamagesImages, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        QUERY_KEYS.inspectionDamageImage,
        id,
      ]);
    },
  });

export const useDeleteInspectionDamagesImages = () =>
  useMutation(inspections.deleteInspectionsDamagesImage, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([QUERY_KEYS.inspectionDamageImage]);
    },
  });

export const useInspectionsVehiclesSummaries = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsVehiclesSummaries, id],
    async () => (await inspections.getInspectionsVehiclesSummaries(id)).data,
  );

export const useInspectionsVehiclesMechanicalsNotes = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsMechanicalsNotes, id],
    async () =>
      (await inspections.getInspectionsVehiclesMechanicalsNotes(id)).data,
  );

export const useInspectionsMechanicalsWheels = (id: number) =>
  useQuery(
    [QUERY_KEYS.inspectionsMechanicalsWheels, id],
    async () => (await inspections.getInspectionsMechanicalsWheels(id)).data,
  );

export const useInspectionsVehicleDamageCodes = (
  siteId?: number | string,
  hasData?: boolean,
) =>
  useQuery(
    [QUERY_KEYS.damageCodes, siteId],
    async () => {
      const { data } = await inspections.getInspectionsVehicleDamageCodes(
        siteId!,
      );

      const mapToSelectItem = (damage: DamageCause): Select => ({
        label: damage?.description,
        value: damage?.code,
      });

      const mapSeverityToSelectItem = (severity: Severity): Select => ({
        label: severity?.description,
        value: `${severity?.severityId}`,
      });

      const mapInternalGradingToSelectItem = (
        internalGrading: InternalGrading,
      ): Select => ({
        label: internalGrading?.description,
        value: internalGrading?.internalGradingId,
      });

      return {
        damageCauses: data?.damageCauses?.map(mapToSelectItem),
        damageConditions: {
          options: data?.damageConditions?.map(mapToSelectItem),
          getSeverityOptions: (damageConditionCode?: string) =>
            data?.damageConditions
              ?.find((item) => item?.code === damageConditionCode)
              ?.severities?.map(mapSeverityToSelectItem),
        },
        repairMethods: data?.repairMethods?.map(mapToSelectItem),
        internalGradings: data?.internalGradings?.map(
          mapInternalGradingToSelectItem,
        ),
        responsibilities: {
          options: data?.responsibilities?.map(mapToSelectItem),
          getItem: (value: string) =>
            data?.responsibilities?.find(
              (responsibility) => responsibility?.code === value,
            ),
          getResponsibilityShortcutsOptions: (responsibilityCode?: string) =>
            data?.responsibilities
              ?.find(
                (responsibility) => responsibility?.code === responsibilityCode,
              )
              ?.availableResponsibilityShortcuts?.map(mapToSelectItem),
          getDealersOptions: (responsibilityCode?: string) =>
            data?.responsibilities
              ?.find(
                (responsibility) => responsibility?.code === responsibilityCode,
              )
              ?.availableDealers?.map(mapToSelectItem),
        },
        workCenters: data?.workCenters?.map(
          (workCenter: DamageCause): Select => ({
            label: `${workCenter?.description} - ${workCenter?.code}`,
            value: workCenter?.code,
          }),
        ),
      };
    },
    { enabled: !!siteId && !hasData },
  );

export const useInspectionsVehicleDamageConditionCodes = (
  partCode: string | number,
) =>
  useQuery(
    [QUERY_KEYS.damageConditionCodes, partCode],
    async () => {
      const { data } =
        await inspections.getInspectionsVehicleDamageConditionCodes(partCode);

      return {
        options: data?.map(
          (damage: DamageCause): Select => ({
            label: damage?.description,
            value: damage?.code,
          }),
        ),
        getSeverityOptions: (damageConditionCode?: string) =>
          data
            ?.find((item) => item?.code === damageConditionCode)
            ?.severities?.map(
              (severity: Severity): Select => ({
                label: severity?.description,
                value: `${severity?.severityId}`,
              }),
            ),
      };
    },
    { enabled: !!partCode },
  );

export const useCreateInspectionsVehiclesDamagesLines = () =>
  useMutation(inspections.createInspectionsVehiclesDamagesLines);

export const useUpdateInspectionsVehiclesDamagesLines = () =>
  useMutation(inspections.updateInspectionsVehiclesDamagesLines);

export const useDeleteInspectionsVehiclesDamagesLines = () =>
  useMutation(inspections.deleteInspectionsVehiclesDamagesLines);

export const useUnDeleteInspectionsVehiclesDamagesLines = () =>
  useMutation(inspections.unDeleteInspectionsVehiclesDamagesLines);

export const useCreateTNROverride = () =>
  useMutation(inspections.postTNROverride);

export const useTNRDetails = (id: number) =>
  useQuery(
    [QUERY_KEYS.tnrdetails, id],
    async () => (await inspections.getTNRDetails(id)).data,
  );

export const useInspectionMOT = (id: number) =>
  useMutation(async () => (await inspections.getInspectionMOT(id)).data);
