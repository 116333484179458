import React, { useEffect, useState } from 'react';
import { PageContainer } from '@/ui/components/Page';
import { useInspectionMOT } from '@/api/Inspections';
import { Inspection, MOTData } from '@/types';
import { Col, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import Descriptions from 'antd/es/descriptions';
import { faCircleCheck, Icon } from '@/ui/components/Icon';

export const MOT = ({
  inspection,
  active,
}: {
  inspection: Inspection;
  active?: boolean;
}) => {
  const { mutateAsync: getMOT, isLoading } = useInspectionMOT(inspection.id);
  const [data, setData] = useState<MOTData>();

  useEffect(() => {
    if (active) getMOT().then((response) => setData(response));
  }, [active, getMOT]);

  return (
    <PageContainer>
      <Spin spinning={isLoading}>
        {data ? (
          <Row gutter={16}>
            <Col span={14}>
              <div className="h-100 bg-light p-lg-5 p-md-3">
                <Descriptions
                  layout="vertical"
                  className="mot-descriptions"
                  column={2}
                  items={[
                    {
                      label: data.registration,
                      span: 2,
                      children: (
                        <strong>
                          <h2>
                            {data.make} {data.model}
                          </h2>
                        </strong>
                      ),
                    },
                    {
                      label: 'Colour',
                      span: 1,
                      children: <h3>{data.primaryColour}</h3>,
                    },
                    {
                      label: 'Fuel Type',
                      span: 1,
                      children: <h3>{data.fuelType}</h3>,
                    },
                    {
                      label: 'Date Registered',
                      span: 1,
                      children: <h3>{data.registrationDate}</h3>,
                    },
                    {
                      label: 'MOT Valid Until',
                      span: 1,
                      children: <h3>{data.motTestDueDate}</h3>,
                    },
                  ]}
                />
              </div>
            </Col>
            <Col span={10}>
              <div className="mot-section h-100 d-flex flex-column justify-content-center text-center">
                <h1>
                  <Icon icon={faCircleCheck} /> MOT
                </h1>
                <p>Days until next MOT is Due</p>
                <h3>
                  {dayjs(data.motTestDueDate)
                    .endOf('day')
                    .diff(dayjs(), 'days')}{' '}
                  days
                </h3>
              </div>
            </Col>
          </Row>
        ) : null}
      </Spin>
    </PageContainer>
  );
};
