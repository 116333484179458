import React, {
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DropDownBox from 'devextreme-react/drop-down-box';
import 'devextreme/dist/css/dx.light.css';
import { List } from 'devextreme-react/list';

import {
  Form,
  Row,
  Col,
  TextArea,
  FileUploader,
  ValueChangedEvent,
} from '@/ui';
import { useTranslate } from '@/translations';
import {
  DamageLineFormType,
  UpdateDamageLineFormType,
} from '@/types/DamagesLines/DamagesLines';
import {
  useInspectionsVehicleDamageCodes,
  useInspectionsVehicleDamageConditionCodes,
  useInspectionsVehicleDamageParts,
} from '@/api/Inspections';
import { CarDamageAreaCode, MAX_FILE_SIZE } from '@/utils/constants';
import { Select } from '@/types/General/Select';
import {
  acceptImageFiles,
  allowedFileExtensions,
} from '@/modules/UploadFile/utils';
import {
  useMechanicalInspection,
  useMechanicalInspectionAvailable,
} from '@/api/MechanicalInspection/hooks/useMechanicalInspection';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import { DamageCodesSelectData } from '@/types';
import { Spin } from 'antd';
import { StyledButton, FileUploaderWrapper } from './DamageLine.styled';

const areaCodeOptions: Select<CarDamageAreaCode>[] = [
  {
    label: 'Front',
    value: CarDamageAreaCode.Front,
  },
  {
    label: 'Nearside',
    value: CarDamageAreaCode.RightSide,
  },
  {
    label: 'Rear',
    value: CarDamageAreaCode.Rear,
  },
  {
    label: 'Offside',
    value: CarDamageAreaCode.LeftSide,
  },
  {
    label: 'Top',
    value: CarDamageAreaCode.Top,
  },
  {
    label: 'Outside',
    value: CarDamageAreaCode.Outside,
  },
  {
    label: 'Underbody',
    value: CarDamageAreaCode.Underbody,
  },
  {
    label: 'Interior',
    value: CarDamageAreaCode.Interior,
  },
];

const sectorIndicesOptions = Array(9)
  .fill(null)
  .map((_: null, idx: number) => idx + 1);

type Props = {
  defaultValue: DamageLineFormType | UpdateDamageLineFormType;
  siteId: number;
  barCode: string;
  onSubmit: (data: DamageLineFormType) => Promise<void>;
  showDamageImageFiles?: boolean;
  loading?: boolean;
};

export const DamageLineForm: FC<Props> = ({
  defaultValue,
  onSubmit,
  barCode,
  siteId,
  showDamageImageFiles = true,
  loading,
}) => {
  const ref = useRef<FileUploader>(null);
  const { t } = useTranslate();

  const vehicleDamageParts = useInspectionsVehicleDamageParts();
  const partCodeOptionsMap = useMemo(
    () =>
      vehicleDamageParts.data?.selectItems ||
      ({} as Record<CarDamageAreaCode, Select[]>),
    [vehicleDamageParts],
  );

  const [formData, setFormData] = useState(defaultValue);
  const [validated, setValidated] = useState(false);
  const [damageCodes, setDamageCodes] = useState<DamageCodesSelectData>();

  const { data: allDamageCodes, isLoading: isDamageCodesLoading } =
    useInspectionsVehicleDamageCodes(siteId, !!damageCodes);

  const {
    data: damageConditionCodes,
    isLoading: isDamageConditionCodesLoading,
  } = useInspectionsVehicleDamageConditionCodes(formData.partCode);

  const { data: mechanicalInspectionAvailable } =
    useMechanicalInspectionAvailable(barCode);

  useEffect(() => {
    if (!allDamageCodes) return;

    const data = { ...allDamageCodes };
    if (damageConditionCodes) {
      data.damageConditions = { ...damageConditionCodes };
    }
    setDamageCodes(data);
  }, [allDamageCodes, damageConditionCodes]);

  const {
    data: mechanicalInspectionData,
    isLoading: isMechanicalInspectionDataLoading,
  } = useMechanicalInspection(barCode, {
    enabled: !!mechanicalInspectionAvailable?.inspectionAvailable,
  });

  const availableDamageConditionSeverityOptions = useMemo(
    () =>
      damageCodes?.damageConditions?.getSeverityOptions(
        formData.damageConditionCode,
      ),
    [damageCodes?.damageConditions, formData.damageConditionCode],
  );
  const responsibilityShortcutsOptions = useMemo(
    () =>
      damageCodes?.responsibilities?.getResponsibilityShortcutsOptions(
        formData.responsibilityCode,
      ),
    [damageCodes?.responsibilities, formData.responsibilityCode],
  );
  const availableDealersOptions = useMemo(
    () =>
      damageCodes?.responsibilities?.getDealersOptions(
        formData.responsibilityCode,
      ),
    [damageCodes?.responsibilities, formData.responsibilityCode],
  );

  const isDealerCodeRequired = !!availableDealersOptions?.length;

  const handleSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = e.target;

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    },
    [setFormData],
  );

  const handleValueChange = (e: ValueChangedEvent) => {
    const files = e.value as File[];
    const readers = [];

    if (!files.length) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        damageImageFiles: [],
      }));

      return;
    }

    // convert to base64 string
    const readFileAsDataUrl = (file: File) =>
      new Promise((resolve, reject) => {
        const fr = new FileReader();

        fr.onload = () => {
          resolve(fr.result);
        };

        fr.onerror = () => {
          reject(fr);
        };

        fr.readAsDataURL(file);
      });

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      readers.push(readFileAsDataUrl(files[i]));
    }

    Promise.all(readers).then((base64Array) => {
      const damageImageFiles = base64Array.map((base64, idx) => ({
        imageFileName: files[idx].name,
        imageFileBase64: base64 as string,
      }));

      setFormData((prevFormData) => ({
        ...prevFormData,
        damageImageFiles,
      }));
    });
  };

  const checkValidity = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      const form = event.currentTarget;

      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);

        return false;
      }

      return true;
    },
    [setValidated],
  );

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      const isValid = checkValidity(event);

      if (!isValid) return;

      event.preventDefault();

      await onSubmit(formData as DamageLineFormType);

      ref.current?.instance?.reset();
    },
    [checkValidity, onSubmit, formData],
  );

  const DEFAULT_OPTION = (
    <option disabled hidden label="Choose option" value="" />
  );

  return (
    <Spin spinning={loading}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="areaCode">
          <Form.Label column sm="4" className="text-center">
            {t('damageArea')}
          </Form.Label>
          <Col sm="8">
            <Form.Select
              name="areaCode"
              value={formData.areaCode}
              onChange={(e) => {
                handleSelectChange(e);
                handleSelectChange({
                  target: { name: 'partCode', value: '' },
                } as React.ChangeEvent<HTMLSelectElement>);
              }}
              required
            >
              {DEFAULT_OPTION}
              {areaCodeOptions.map((item: Select<CarDamageAreaCode>) => (
                <option
                  key={item.label}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </Form.Select>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="partCode">
          <Form.Label column sm="4" className="text-center">
            {t('partComp')}
          </Form.Label>
          <Col sm="8">
            {vehicleDamageParts.isLoading ? (
              <LoadIndicator visible />
            ) : (
              <Form.Select
                name="partCode"
                value={formData.partCode}
                onChange={handleSelectChange}
                required
              >
                {DEFAULT_OPTION}
                {formData.areaCode &&
                  partCodeOptionsMap[formData.areaCode]?.map((item: Select) => (
                    <option
                      key={item.label}
                      label={item.label}
                      value={item.value}
                    />
                  ))}
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="damageCauseCode">
          <Form.Label column sm="4" className="text-center">
            {t('damageCause')}
          </Form.Label>
          <Col sm="8">
            {isDamageCodesLoading ? (
              <LoadIndicator visible />
            ) : (
              <Form.Select
                name="damageCauseCode"
                value={formData.damageCauseCode}
                onChange={handleSelectChange}
                required
              >
                {DEFAULT_OPTION}
                {damageCodes?.damageCauses.map((item: Select) => (
                  <option
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="sectorIndices">
          <Form.Label column sm="4" className="text-center">
            {t('sector')}
          </Form.Label>
          <Col sm="8">
            <DropDownBox
              dataSource={sectorIndicesOptions}
              value={formData.sectorIndices}
              stylingMode="outlined"
            >
              <List
                dataSource={sectorIndicesOptions}
                selectedItems={formData.sectorIndices}
                selectionMode="multiple"
                onSelectionChanged={({ addedItems, removedItems }) => {
                  let newSectorIndices = formData.sectorIndices;

                  if (addedItems.length) {
                    newSectorIndices = [...newSectorIndices, ...addedItems];
                  } else if (removedItems.length) {
                    newSectorIndices = newSectorIndices.filter(
                      (num) => num !== removedItems[0],
                    );
                  }

                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    sectorIndices: newSectorIndices,
                  }));
                }}
              />
            </DropDownBox>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="damageConditionCode">
          <Form.Label column sm="4" className="text-center">
            {t('damageCondition')}
          </Form.Label>
          <Col sm="8">
            {isDamageCodesLoading || isDamageConditionCodesLoading ? (
              <LoadIndicator visible />
            ) : (
              <Form.Select
                name="damageConditionCode"
                value={formData.damageConditionCode}
                onChange={(e) => {
                  handleSelectChange(e);
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    severityId: '' as unknown as number,
                  }));
                }}
                required
              >
                {DEFAULT_OPTION}
                {damageCodes?.damageConditions.options.map((item: Select) => (
                  <option
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        {availableDamageConditionSeverityOptions?.length ? (
          <Form.Group as={Row} className="mb-3" controlId="severityId">
            <Form.Label column sm="4" className="text-center">
              {t('severity')}
            </Form.Label>
            <Col sm="8">
              {isDamageCodesLoading ? (
                <LoadIndicator visible />
              ) : (
                <Form.Select
                  name="severityId"
                  value={formData.severityId}
                  onChange={handleSelectChange}
                  required
                >
                  {DEFAULT_OPTION}
                  {availableDamageConditionSeverityOptions.map(
                    (item: Select) => (
                      <option
                        key={item.label}
                        label={item.label}
                        value={item.value}
                      />
                    ),
                  )}
                </Form.Select>
              )}
            </Col>
          </Form.Group>
        ) : null}

        <Form.Group as={Row} className="mb-3" controlId="repairMethodCode">
          <Form.Label column sm="4" className="text-center">
            {t('repairMethod')}
          </Form.Label>
          <Col sm="8">
            {isDamageCodesLoading ? (
              <LoadIndicator visible />
            ) : (
              <Form.Select
                name="repairMethodCode"
                value={formData.repairMethodCode}
                onChange={handleSelectChange}
                required
              >
                {DEFAULT_OPTION}
                {damageCodes?.repairMethods.map((item: Select) => (
                  <option
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="internalGradingId">
          <Form.Label column sm="4" className="text-center">
            {t('internalGrade')}
          </Form.Label>
          <Col sm="8">
            {isDamageCodesLoading ? (
              <LoadIndicator visible />
            ) : (
              <Form.Select
                name="internalGradingId"
                value={formData.internalGradingId}
                onChange={handleSelectChange}
                required
              >
                {DEFAULT_OPTION}
                {damageCodes?.internalGradings.map((item: Select) => (
                  <option
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="workCenterCode">
          <Form.Label column sm="4" className="text-center">
            {t('workCentre')}
          </Form.Label>
          <Col sm="8">
            {isDamageCodesLoading ? (
              <LoadIndicator visible />
            ) : (
              <Form.Select
                name="workCenterCode"
                value={formData.workCenterCode}
                onChange={handleSelectChange}
                required
              >
                {DEFAULT_OPTION}
                {damageCodes?.workCenters.map((item: Select) => (
                  <option
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="responsibilityCode">
          <Form.Label column sm="4" className="text-center">
            {t('responsibility')}
          </Form.Label>
          <Col sm="8">
            {isDamageCodesLoading ? (
              <LoadIndicator visible />
            ) : (
              <Form.Select
                name="responsibilityCode"
                value={formData.responsibilityCode}
                onChange={(e) => {
                  handleSelectChange(e);
                  handleSelectChange({
                    target: { name: 'responsibilityShortcutCode', value: '' },
                  } as React.ChangeEvent<HTMLSelectElement>);
                  handleSelectChange({
                    target: { name: 'dealerCode', value: '' },
                  } as React.ChangeEvent<HTMLSelectElement>);
                }}
                required
              >
                {DEFAULT_OPTION}
                {damageCodes?.responsibilities.options.map((item: Select) => (
                  <option
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </Form.Select>
            )}
          </Col>
        </Form.Group>

        {responsibilityShortcutsOptions?.length ? (
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="responsibilityShortcutCode"
          >
            <Form.Label column sm="4" className="text-center">
              {t('shortcutCode')}
            </Form.Label>
            <Col sm="8">
              {isDamageCodesLoading ? (
                <LoadIndicator visible />
              ) : (
                <Form.Select
                  name="responsibilityShortcutCode"
                  value={formData.responsibilityShortcutCode}
                  onChange={handleSelectChange}
                  required
                >
                  {DEFAULT_OPTION}
                  {responsibilityShortcutsOptions.map((item: Select) => (
                    <option
                      key={item.label}
                      label={item.label}
                      value={item.value}
                    />
                  ))}
                </Form.Select>
              )}
            </Col>
          </Form.Group>
        ) : null}

        {availableDealersOptions?.length ? (
          <Form.Group as={Row} className="mb-3" controlId="dealerCode">
            <Form.Label column sm="4" className="text-center">
              {t('dealer')}
            </Form.Label>
            <Col sm="8">
              {isDamageCodesLoading ? (
                <LoadIndicator visible />
              ) : (
                <Form.Select
                  name="dealerCode"
                  value={formData.dealerCode}
                  onChange={handleSelectChange}
                  required
                >
                  {DEFAULT_OPTION}
                  {availableDealersOptions.map((item: Select) => (
                    <option
                      key={item.label}
                      label={item.label}
                      value={item.value}
                    />
                  ))}
                </Form.Select>
              )}
            </Col>
          </Form.Group>
        ) : null}

        <Form.Group as={Row} className="mb-3" controlId="note">
          <Form.Label column sm="4" className="text-center">
            {t('notes')}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as={TextArea}
              name="note"
              value={formData.note}
              onValueChanged={({ value }: any) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  note: value,
                }));
              }}
              autoResizeEnabled
              maxHeight={120}
              valueChangeEvent="keyup"
              placeholder={t('addNote')}
              stylingMode="outlined"
            />
          </Col>
        </Form.Group>

        {mechanicalInspectionData?.isMechanical ? (
          <Form.Group as={Row} className="mb-3" controlId="mechanicalFailure">
            <Form.Label column sm="4" className="text-center">
              {t('mechanicalFailure')}
            </Form.Label>
            <Col sm="8">
              {isMechanicalInspectionDataLoading ? (
                <LoadIndicator visible />
              ) : (
                <Form.Select
                  name="mechanicalFailure"
                  value={formData.mechanicalFailure}
                  onChange={handleSelectChange}
                  required={isDealerCodeRequired}
                >
                  {DEFAULT_OPTION}
                  {mechanicalInspectionData?.failToReportList.map(
                    (item: Select) => (
                      <option
                        key={item.value}
                        label={item.label}
                        value={item.value}
                      />
                    ),
                  )}
                </Form.Select>
              )}
            </Col>
          </Form.Group>
        ) : null}

        {showDamageImageFiles ? (
          <Form.Group as={Row} className="mb-3" controlId="damageImageFiles">
            <Form.Label column sm="4" className="text-center">
              {t('damageImages')}
            </Form.Label>
            <Col sm="8">
              <FileUploaderWrapper>
                <Form.Control
                  ref={ref}
                  as={FileUploader}
                  uploadMode="useForm"
                  accept={acceptImageFiles}
                  allowedFileExtensions={allowedFileExtensions(
                    acceptImageFiles,
                  )}
                  onValueChanged={handleValueChange}
                  selectButtonText={t('selectFile')}
                  invalidMaxFileSizeMessage={t('fileIsTooLarge')}
                  maxFileSize={MAX_FILE_SIZE}
                  multiple
                />
              </FileUploaderWrapper>
            </Col>
          </Form.Group>
        ) : null}

        <StyledButton type="submit" variant="success">
          {t('submitDamage')}
        </StyledButton>
      </Form>
    </Spin>
  );
};
