import React, { useEffect, useState } from 'react';
import { Title } from '@/ui/components/Title';
import { useTranslate } from '@/translations';
import { faPassport } from '@/ui/components/Icon';
import { useTheme } from 'styled-components';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '@/hooks';

import { PageContainer } from '@/ui/components/Page';
import { Col, Input } from 'antd';
import { usePassports, usePassportsTransactions } from '@/api/Passport';
import { PassportDetails } from './components/PassportDetails';
import { PassportTransactions } from './components/PassportTransactions';
import { StyledRow, PassportCard, FullWidthSpace } from './Passport.styled';
import { InspectionsDataGrid } from './components/InspectionsDataGrid';

const { Search } = Input;

export const Passport = () => {
  const { t } = useTranslate();
  const { passport } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const idTokenClaims = useIdTokenClaims();
  const tenant = idTokenClaims?.appTenantId;
  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('search') || '',
  );

  const debouncedSearchValue = useDebounce(searchValue, 400);

  const { data: passportsData, isLoading: isPassportsLoading } = usePassports(
    { search: debouncedSearchValue, tenantId: tenant },
    { enabled: !!debouncedSearchValue.length },
  );

  const {
    data: passportsTransactionsData,
    isLoading: isPassportsTransactionLoading,
  } = usePassportsTransactions(
    { search: debouncedSearchValue, tenantId: tenant },
    { enabled: !!debouncedSearchValue.length },
  );

  const passportData = passportsData?.entities[0];

  useEffect(() => {
    if (isPassportsLoading) return;

    if (passportData) {
      setSearchParams({ search: debouncedSearchValue });
    } else if (searchParams.has('search')) {
      setSearchParams({});
    }
  }, [
    debouncedSearchValue,
    passportData,
    isPassportsLoading,
    searchParams,
    setSearchParams,
  ]);

  return (
    <PageContainer>
      <Title title={t('passport')} icon={faPassport} color={passport} />
      <div className="d-flex justify-content-center">
        <Search
          placeholder={t('search')}
          onSearch={(value) => setSearchValue(value)}
          style={{ width: '300px' }}
          maxLength={17}
          allowClear
        />
      </div>

      {!!debouncedSearchValue.length && (
        <StyledRow gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={12}>
            <PassportCard>
              <PassportDetails
                passportData={passportData}
                isLoading={isPassportsLoading}
              />
            </PassportCard>
          </Col>

          <Col xs={24} sm={24} lg={12}>
            <FullWidthSpace direction="vertical" size={16}>
              <PassportCard>
                <PassportTransactions
                  passportsTransactionsData={passportsTransactionsData}
                  isLoading={isPassportsTransactionLoading}
                />
              </PassportCard>
              <PassportCard>
                <InspectionsDataGrid
                  passportsData={passportsData}
                  isLoading={isPassportsLoading}
                />
              </PassportCard>
            </FullWidthSpace>
          </Col>
        </StyledRow>
      )}
    </PageContainer>
  );
};
