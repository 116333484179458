import React, { FC, ReactElement, useCallback, useMemo } from 'react';

import { useTranslate } from '@/translations';
import {
  currencyFormatted,
  getDifferenceInMonthsFromDate,
} from '@/utils/helpers';
import { TranslationKey } from '@/translations/types';
import {
  faCircleCheck,
  faCircleXmark,
  faCircleQuestion,
  Icon,
} from '@/ui/components/Icon';
import { useCreateTNROverride } from '@/api/Inspections';
import { Button, Col, Collapse, Descriptions, Row, Slider } from 'antd';
import { SalesDecisionCardProps } from './types';
import { TNRStatusPill } from './SalesDecisionCard.styled';

// marks values for different sliders
const eighteen = 18;
const nine = 9;
const tenThousands = 10_000;
const thirtyThousands = 30_000;
const twentyThousands = 20_000;

// floated values, Auto NOK is 90% of max value, just to make Auto NOK mark in one line with other sliders
const ageMaxValue = (eighteen / 90) * 100;
const mileageMaxValue = (thirtyThousands / 90) * 100;

export const SalesDecisionCard: FC<SalesDecisionCardProps> = ({
  inspection,
  mechanicalInspection,
  tnr,
}) => {
  const { t } = useTranslate();
  let status = 'Not Eligible';

  const ageDefaultValue = getDifferenceInMonthsFromDate(
    inspection.inspectionDateTime,
  );
  const mileageDefaultValue = inspection.mileage;
  let repairCostDefaultValue = inspection.finalCost;

  if (tnr?.damageCost) {
    repairCostDefaultValue = tnr?.damageCost;
  }

  const netPrice = tnr?.netPrice as number;
  let repairCostMaxValue = twentyThousands * 0.9;
  let startApproveForRepair = twentyThousands * 0.7;
  let price = twentyThousands;
  if (tnr?.netPrice) {
    repairCostMaxValue = netPrice * 0.225;
    startApproveForRepair = Math.round(netPrice * 0.21);
    price = netPrice * 0.3;
    status = 'Eligible';
  }

  const maintenanceHistory = true;
  const ista = mechanicalInspection?.carryOutIstaVehicleTest;
  const tnrOverride = tnr?.overrideId;
  const vinOverride = tnr?.vin;
  const tnrOverrideResult = tnr?.override;

  const tnrRegistrationDate = tnr?.registrationDate as string;

  const tnrAge = getDifferenceInMonthsFromDate(tnrRegistrationDate);

  let ageValue = tnrAge;

  if (!tnrAge) {
    ageValue = ageDefaultValue;
  }

  let chiptuningDetected = true;

  if (ista === 'Pass' || ista === 'NotApplicable' || !ista) {
    chiptuningDetected = false;
  }


  let approvedTNR = true;

  if(!maintenanceHistory || chiptuningDetected || tnrOverrideResult === 'true' || repairCostDefaultValue > repairCostMaxValue || ageDefaultValue >= 18 || mileageDefaultValue > thirtyThousands || !netPrice){
    approvedTNR = false
  }

  const { mutateAsync: createTNROverride } = useCreateTNROverride();

  const inspectionStatus = inspection?.inspectionStatus;

  const handleTNROverride = useCallback(
    async (override: string) => {
      try {
        await createTNROverride({
          vin: inspection.vin,
          override,
        });
        window.location.reload();
      } catch (error) {
        console.log('handleTNROverride: ', { error });
      }
    },
    [createTNROverride, inspection],
  );

  const getCategoryLabel = useCallback(
    (currentValue: number, maxValue: number): ReactElement => {
      if (currentValue > maxValue) {
        return <Icon icon={faCircleXmark} color="red" />;
      }

      if (currentValue > maxValue * 0.7) {
        // if currentValue between 70% and 100%
        return <Icon icon={faCircleQuestion} color="red" />;
      }

      return <Icon icon={faCircleCheck} color="green" />;
    },
    [],
  );

  const getCategoryLabel1 = useCallback(
    (
      currentValue: number,
      maxValue: number,
      minValue: number,
    ): ReactElement => {
      if (currentValue > maxValue) {
        return <Icon icon={faCircleXmark} color="red" />;
      }

      if (currentValue > minValue && currentValue < maxValue) {
        return <Icon icon={faCircleQuestion} color="red" />;
      }

      return <Icon icon={faCircleCheck} color="green" />;
    },
    [],
  );

  const getButtons = useCallback(
    (
      startReject: number,
      repairCostMax: number,
      repairCostDefault: number,
      mileage: number,
      minMileage: number,
      maxMileage: number,
      age: number,
      overrideId?: number,
      vin?: string,
    ) => {
      if (
        overrideId ||
        !vin ||
        repairCostDefault > repairCostMax ||
        mileage > maxMileage ||
        ageDefaultValue >= 18 ||
        inspectionStatus >= 8
      ) {
        return <Row className="mt-3"> </Row>;
      }
      if (
        vin &&
        !overrideId &&
        ((repairCostDefault > startReject &&
          repairCostDefault < repairCostMax) ||
          (mileage > minMileage && mileage < maxMileage) ||
          (age >= nine && age < eighteen))
      ) {
        return (
            <Row className="mt-3">
              <Button color="danger" onClick={() => handleTNROverride('true')}>
                {t('rejectForRepair')}
              </Button>
            </Row>
        );
      }

      return (
        <>
          <Row className="mt-3">
            <Button
              type="primary"
              onClick={() => handleTNROverride('false')}
              disabled
            >
              {t('approveForRepair')}
            </Button>
          </Row>
          <Row className="mt-3">
            <Button
              color="danger"
              onClick={() => handleTNROverride('true')}
              disabled
            >
              {t('rejectForRepair')}
            </Button>
          </Row>
        </>
      );
    },
    [handleTNROverride, ageDefaultValue, inspectionStatus, t],
  );
  const sliderConfigs = useMemo(
    () => [
      {
        title: 'age',
        min: 0,
        max: ageMaxValue,
        defaultValue: ageValue,
        marks: {
          [ageValue]: {
            label: `${ageValue} ${t(ageValue === 1 ? 'month' : 'months')}`,
            style: {
              textWrap: 'nowrap',
              ...(ageValue > ageMaxValue && { left: '100%' }),
              ...(ageValue < ageMaxValue / 2 && { transform: 'none' }), // avoiding text overflow
            },
          },
          [nine]: {
            label: `${nine} months`,
          },
          [eighteen]: {
            label: t('autoNOK'),
            style: {
              textWrap: 'nowrap',
            },
          },
        },
        trackClass:
          ageValue < nine
            ? 'track-green'
            : ageValue < eighteen
            ? 'track-yellow'
            : 'track-red',
      },
      {
        title: 'mileage',
        min: 0,
        max: mileageMaxValue,
        defaultValue: mileageDefaultValue,
        marks: {
          [mileageDefaultValue]: {
            label: `${mileageDefaultValue} m`,
            class: 'mileage',
            style: {
              textWrap: 'nowrap',
              ...(mileageDefaultValue >= mileageMaxValue && { left: '100%' }),
              ...(mileageDefaultValue < mileageMaxValue * 0.1 && {
                transform: 'none',
              }), // avoiding text overflow
            },
          },
          [tenThousands]: {
            label: `${tenThousands} m`,
          },
          [thirtyThousands]: {
            label: t('autoNOK'),
            style: {
              textWrap: 'nowrap',
            },
          },
        },
        trackClass:
          mileageDefaultValue < tenThousands
            ? 'track-green'
            : mileageDefaultValue < thirtyThousands
            ? 'track-yellow'
            : 'track-red',
      },
      {
        title: 'repairCost',
        min: 0,
        max: price,
        defaultValue: repairCostDefaultValue,
        marks: {
          [repairCostDefaultValue]: {
            label: currencyFormatted(repairCostDefaultValue, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
            style: {
              textWrap: 'nowrap',
              ...(repairCostDefaultValue > repairCostMaxValue && {
                left: '100%',
              }),
              ...(repairCostDefaultValue < repairCostMaxValue / 2 && {
                transform: 'none',
              }), // avoiding text overflow
            },
          },
          [startApproveForRepair]: {
            label: `£${startApproveForRepair}`,
            style: {
              textWrap: 'nowrap',
              left: '70%',
            },
          },
          [repairCostMaxValue]: {
            label: t('autoNOK'),
            style: {
              textWrap: 'nowrap',
              left: '75%',
            },
          },
        },
        trackClass:
          repairCostDefaultValue < repairCostMaxValue
            ? 'track-green'
            : 'track-red',
      },
    ],
    [
      t,
      ageValue,
      mileageDefaultValue,
      repairCostDefaultValue,
      repairCostMaxValue,
      price,
      startApproveForRepair,
    ],
  );

  const infoList = useMemo(
    () => [
      {
        label: t('maintenanceHistory'),
        value: maintenanceHistory ? t('yes') : t('no'),
        currentValue: maintenanceHistory ? 0 : 20,
        maxValue: 10,
      },
      {
        label: t('chiptuningDetected'),
        value: chiptuningDetected ? t('yes') : t('no'),
        currentValue: chiptuningDetected ? 20 : 0,
        maxValue: 10,
      },
      {
        label: t('age'),
        value: `${ageValue} ${t(ageValue === 1 ? 'month' : 'months')}`,
        currentValue: ageValue,
        maxValue: eighteen,
      },
      {
        label: t('mileage'),
        value: `${mileageDefaultValue} m`,
        currentValue: mileageDefaultValue,
        maxValue: thirtyThousands,
      },
      {
        label: t('repairCost'),
        value: currencyFormatted(repairCostDefaultValue, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currentValue: repairCostDefaultValue,
        maxValue: repairCostMaxValue,
        minValue: startApproveForRepair,
      },
      {
        label: t('tnrApprove'),
        value: approvedTNR ? t('yes') : t('no'),
        currentValue: approvedTNR ? 0 : 20,
        maxValue: 10,
      },
    ],
    [
      t,
      maintenanceHistory,
      ageValue,
      chiptuningDetected,
      mileageDefaultValue,
      repairCostDefaultValue,
      repairCostMaxValue,
      startApproveForRepair,
      approvedTNR,
    ],
  );

  return (
    <Collapse
      bordered={false}
      items={[
        {
          key: '1',
          label: (
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              {t('tnrStatusColon')}
              <TNRStatusPill status={status} className="ms-3">
                {status}
              </TNRStatusPill>
            </div>
          ),
          children: (
            <Row gutter={16}>
              <Col xs={24} lg={18}>
                <div className="d-flex flex-column gap-2">
                  {sliderConfigs.map(
                    ({ title, min, max, defaultValue, marks, trackClass }) => (
                      <div key={title}>
                        <span className="fw-bold fs-6">
                          {t(title as TranslationKey)}
                        </span>
                        <Slider
                          min={min}
                          max={max}
                          value={defaultValue}
                          marks={marks}
                          step={1}
                          tooltip={{ open: false }}
                          disabled
                          style={{ cursor: 'default' }}
                          className={trackClass}
                        />
                      </div>
                    ),
                  )}
                </div>
              </Col>
              <Col xs={24} lg={6} className="d-flex flex-column gap-3">
                <Descriptions
                  column={1}
                  layout="vertical"
                  items={infoList.map(
                    ({ label, value, currentValue, maxValue, minValue }) => ({
                      key: label,
                      label,
                      children: (
                        <div className="d-flex align-items-center gap-3">
                          {value}
                          {label === 'repairCost'
                            ? getCategoryLabel1(
                                currentValue,
                                maxValue,
                                minValue!,
                              )
                            : getCategoryLabel(currentValue, maxValue)}
                        </div>
                      ),
                    }),
                  )}
                />
                {getButtons(
                  startApproveForRepair,
                  repairCostMaxValue,
                  repairCostDefaultValue,
                  mileageDefaultValue,
                  tenThousands,
                  thirtyThousands,
                  ageValue,
                  tnrOverride,
                  vinOverride,
                )}
              </Col>
            </Row>
          ),
          style: {
            background: '#fff',
            borderRadius: 8,
            border: '1px solid #f0f0f0',
          },
        },
      ]}
    />
  );
};
