import React, { FC, useCallback } from 'react';
import { useTranslate } from '@/translations';
import {
  DamageLineFormType,
  CreateDamageLineBody,
} from '@/types/DamagesLines/DamagesLines';
import { useCreateInspectionsVehiclesDamagesLines } from '@/api/Inspections';
import { useIdTokenClaims } from '@/hooks/useIdTokenClaims';
import { useLocationsSites } from '@/api/Locations/hooks/useLocations';
import { Inspection } from '@/types';
import { CarDamageAreaCode } from '@/utils/constants';

import { Modal } from 'antd';
import { DamageLineForm } from './DamageLineForm';

const DEFAULT_FORM_DATA: DamageLineFormType = {
  areaCode: '' as CarDamageAreaCode,
  partCode: '',
  damageCauseCode: '',
  sectorIndices: [],
  damageConditionCode: '',
  severityId: '' as unknown as number, // prevent preselect first option in Form.Select
  repairMethodCode: '',
  internalGradingId: '' as unknown as number, // prevent preselect first option in Form.Select
  workCenterCode: '',
  responsibilityCode: '',
  responsibilityShortcutCode: '',
  dealerCode: '',
  note: '',
  mechanicalFailure: '',
  damageImageFiles: [],
};

type Props = {
  isPopupVisible: boolean;
  onTogglePopup: () => void;
  onSubmited: () => void;
  inspection: Inspection;
};

export const AddDamageLinePopup: FC<Props> = ({
  isPopupVisible,
  onTogglePopup,
  onSubmited,
  inspection,
}) => {
  const idTokenClaims = useIdTokenClaims();
  const tenantId = Number(idTokenClaims?.appTenantId);
  const barCode = inspection.vin;
  const inspectionId = inspection.id;
  const userName = idTokenClaims?.userDisplayName!;

  const { data: locationsSitesData } = useLocationsSites(tenantId, {
    enabled: !!tenantId,
  });

  const siteId = locationsSitesData?.entities.find(
    ({ siteName }) => siteName === inspection.inspectionLocation,
  )?.siteId!;

  const { t } = useTranslate();
  const {
    mutateAsync: createInspectionsVehiclesDamagesLinesMutateAsync,
    isLoading,
  } = useCreateInspectionsVehiclesDamagesLines();

  const defaultSubmit = useCallback(
    async ({ sectorIndices, ...data }: DamageLineFormType) => {
      const body: CreateDamageLineBody = {
        ...data,
        sectorIndices: sectorIndices.filter(Boolean), // clear 0 values
        tenantId,
        userName,
        inspectionId,
        siteId,
      };

      await createInspectionsVehiclesDamagesLinesMutateAsync(body);
      onSubmited();
    },
    [
      createInspectionsVehiclesDamagesLinesMutateAsync,
      inspectionId,
      onSubmited,
      siteId,
      tenantId,
      userName,
    ],
  );

  const handleTogglePopup = useCallback(() => {
    onTogglePopup();
  }, [onTogglePopup]);

  const handleSubmit = useCallback(
    async (data: DamageLineFormType) => {
      await defaultSubmit(data);

      handleTogglePopup();
    },
    [defaultSubmit, handleTogglePopup],
  );

  return (
    <Modal
      open={isPopupVisible}
      onCancel={handleTogglePopup}
      title={t('addDamage')}
      maskClosable
      footer={null}
    >
      <DamageLineForm
        defaultValue={DEFAULT_FORM_DATA}
        onSubmit={handleSubmit}
        barCode={barCode}
        siteId={siteId}
        loading={isLoading}
      />
    </Modal>
  );
};
